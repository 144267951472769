import BigNumber from 'bignumber.js';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { useLocale } from 'src/hooks/use-locale';
import { coinsAtom } from 'src/store/wallet';
import { BN } from 'src/utils/helpers/big-number';

interface TokenAmountProps {
  amount: BigNumber.Value;
  symbol?: string;
  prefix?: string;
  decimal?: number;
  suffix?: string;
  roundingMode?: BigNumber.RoundingMode;
  fullFormat?: boolean;
}

const TokenAmount = ({
  amount,
  prefix = '',
  decimal,
  suffix,
  symbol,
  roundingMode,
  fullFormat = false,
}: TokenAmountProps) => {
  const { decimalSeparator, FORMAT } = useLocale();
  const coins = useAtomValue(coinsAtom);
  const isFiat = _.find(
    coins,
    (c) => c.symbol === symbol?.toLocaleLowerCase(),
  )?.isFiat;

  const formatTokenAmount = (value: BigNumber.Value) => {
    const format = {
      decimalSeparator,
      ...(fullFormat ? FORMAT : {}),
      prefix: `${prefix}${isFiat ? '$' : ''}`,
      suffix: suffix ? ` ${suffix}` : '',
    };

    const Big = BigNumber.clone({
      ...(roundingMode ? { ROUNDING_MODE: roundingMode } : {}),
    });

    if (isFiat) return new BigNumber(value).toFormat(2, format);

    if (decimal) return new Big(value).dp(decimal).toFormat(2, format);
    return new Big(value).toFormat(format);
  };

  return formatTokenAmount(amount);
};

export default TokenAmount;
